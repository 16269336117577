import api from './index'
import authHeader from './auth-header'
import { saveAs } from 'file-saver'

class serviceStaff {

    getAll() {
        return api.get('staff/all', { headers: authHeader() })
    }

    insert(formData) {
        return api.post('staff/create', formData, { headers: authHeader() })
    }

    uploadProfile(formData) {
        return api.post('staff/profile/upload', formData, { headers: authHeader() })
    }

    getPictureByStaffId(id) {
        //       return api.get('staff/get/picture/' + id, { headers: authHeader() })
        return api.get('staff/get/picture/' + id, { responseType: 'blob', headers: authHeader() })

    }


}

export default new serviceStaff()