import api from './index'
import authHeader from './auth-header'

class serviceStaffDetail {

    getTrainingAll() {
        return api.get('training/all', { headers: authHeader() })
    }

    getTrainingByStaffId(id) {
        return api.get('training/staffId/' + id, { headers: authHeader() })
    }

    getEducationByStaffId(id) {
        return api.get('education/staffId/' + id, { headers: authHeader() })
    }

    getWorkByStaffId(id) {
        return api.get('work/staffId/' + id, { headers: authHeader() })
    }

    getRelativesByStaffId(id) {
        return api.get('relatives/staffId/' + id, { headers: authHeader() })
    }

    getPoliticalServiceByStaffId(id) {
        return api.get('politicalService/staffId/' + id, { headers: authHeader() })
    }

    getLanguageSkillByStaffId(id) {
        return api.get('skill/language/staffId/' + id, { headers: authHeader() })
    }

    getComputerSkillByStaffId(id) {
        return api.get('skill/computer/staffId/' + id, { headers: authHeader() })
    }

    getMedalHistoryByStaffId(id) {
        return api.get('medalHistory/staffId/' + id, { headers: authHeader() })
    }

    insertTrainingBatch(formData) {
        return api.post('training/createBatch', formData, { headers: authHeader() })
    }

    insertWorkBatch(formData) {
        return api.post('work/createBatch', formData, { headers: authHeader() })
    }

    insertEducationBatch(formData) {
        return api.post('education/createBatch', formData, { headers: authHeader() })
    }

    insertRelativesBatch(formData) {
        return api.post('relatives/createBatch', formData, { headers: authHeader() })
    }

    insertPoliticalServiceBatch(formData) {
        return api.post('politicalService/createBatch', formData, { headers: authHeader() })
    }

    insertLanguageSkillBatch(formData) {
        return api.post('skill/language/createBatch', formData, { headers: authHeader() })
    }

    insertComputerSkillBatch(formData) {
        return api.post('skill/computer/createBatch', formData, { headers: authHeader() })
    }

    insertMedalHistoryBatch(formData) {
        return api.post('medalHistory/createBatch', formData, { headers: authHeader() })
    }

}

export default new serviceStaffDetail()